import { useEffect } from 'react';

const Youtube = () => {
  useEffect(() => {
    window.location.href = 'https://studio.youtube.com';
  }, []);

  return null;
};

export default Youtube;
