import React, {useRef} from 'react';
import { Apple, ArrowRight, Facebook, Instagram, Smartphone } from 'lucide-react';
import emailjs from '@emailjs/browser';

export default function Home() {

  const form = useRef();
  const [sending, setSending] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(true);

    // see

    emailjs
      .sendForm('service_udbfcc6', 'template_kmhzzj1', form.current, {
        publicKey: 'ueJudwZPBFbHF_9EI',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSending(false);
          setSent(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setSending(false);
          setSent(false);
          alert("Your message could not be sent. Please try again react out at: info@estamart.com")
        },
      );
  };
  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <a className="flex items-center justify-center" href="/">
          <Smartphone className="h-6 w-6" />
          <span className="ml-2 text-lg font-bold">Esta Mart</span>
        </a>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#download">
            Download
          </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#privacy">
            Privacy
          </a>

          <a className="text-sm font-medium hover:underline underline-offset-4" href="/return-policy">
            Refund/Return Policy
          </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#contact">
            Contact
          </a>
        </nav>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 justify-center items-center flex">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Fresh Groceries at Your Doorstep
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
                  Esta Mart brings high-quality groceries to your home in Johar Town, Wapda Town, and Ali Town, Lahore.
                  Shop easily, get fast delivery, and enjoy the best local produce.
                </p>
              </div>
              <div className="space-x-4">
                <a
                  className="inline-flex h-9 items-center justify-center rounded-md bg-gray-900 px-4 py-2 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                  href="https://play.google.com/store/apps/details?id=com.estamart.appdev"
                >
                  Download Now
                </a>
                <a
                  className="inline-flex h-9 items-center justify-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
                  href="#contact"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800  justify-center items-center flex">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Contact Us</h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  Have questions or feedback? We'd love to hear from you. Reach out to us using the form below or
                  contact us directly.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form className="flex flex-col gap-2" ref={form} onSubmit={sendEmail}>
                  <input className="border p-2 rounded" placeholder="Your Name" name='from_name' type="text" />
                  <input className="border p-2 rounded" placeholder="Your Email" type="email" name="from_email" />
                  <input className="border p-2 rounded" placeholder="Your Message" name='message' />
                  <button disabled={sending || sent} className="bg-gray-900 text-white p-2 rounded" type="submit"> {sending?"Sending...":sent?"Sent!":"Send"}</button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section id="download" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800  justify-center items-center flex">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Download Our App</h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  Get the Esta Mart app now and start enjoying fresh groceries delivered to your doorstep in Johar Town,
                  Wapda Town, and Ali Town.
                </p>
              </div>
              <div className="flex flex-col gap-2 min-[400px]:flex-row">
                <a
                  className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                  href="https://apps.apple.com/us/app/esta-mart/id1663611063"
                >
                  <Apple className="mr-2 h-5 w-5" />
                  App Store
                </a>
                <a
                  className="inline-flex h-10 items-center justify-center rounded-md border border-gray-200 bg-white px-8 text-sm font-medium shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
                  href="https://play.google.com/store/apps/details?id=com.estamart.appdev"
                >
                  <Smartphone className="mr-2 h-5 w-5" />
                  Google Play
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="privacy" className="w-full py-12 md:py-24 lg:py-32  justify-center items-center flex">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Privacy Policy</h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  At Esta Mart, we value your privacy and are committed to protecting your personal information. Our
                  privacy policy outlines how we collect, use, and safeguard your data.
                </p>
              </div>
              <a
                className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-8 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                href="/privacy-policy"
              >
                Read Full Policy
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
            </div>
          </div>
        </section>
        
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">© 2024 Esta Mart. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs hover:underline underline-offset-4" href="tel:+923258261189">
            Call us: +92 325 8261189
          </a>
          <a className="text-xs hover:underline underline-offset-4" href="https://wa.me/923258261189">
            WhatsApp
          </a>
          <a className="text-xs hover:underline underline-offset-4" href="#">
            <Facebook className="h-4 w-4" />
            <span className="sr-only">Facebook</span>
          </a>
          <a className="text-xs hover:underline underline-offset-4" href="https://www.instagram.com/estamart.official">
            <Instagram className="h-4 w-4" />
            <span className="sr-only">Instagram</span>
          </a>
        </nav>
      </footer>
    </div>
  );
}
